import React from "react"
import styled from "styled-components"
import LinkdIn from "../../atoms/SVGs/LinkdIn"
import CVIcon from "../../atoms/SVGs/CVIcon"
import ContactForm from "../../molecules/ContactForm/ContactForm"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import PDF from "../../../CV.pdf"
import VideoPlayer from "../../atoms/VideoPlayer/VideoPlayer"

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
`
const P = styled.p`
  font-size: 18px;
  text-align: center;

  @media (min-width: 768px) {
    width: 75%;
  }
`

const Link = styled.a`
  border: none;
  background: transparent;
  svg {
    height: 45px;
  }
`
const BtnWrapper = styled.div`
  display: flex;
  margin-top: 12px;
`

const Home = () => {
  return (
    <Container>
      <P>
        Hello I am a front end developer based in Glasgow specialising in React
        with a keen interest in UX.
      </P>
      <VideoPlayer />
      <P style={{ fontSize: "12px", marginBottom: 0 }}>
        The above video was made with React and Styled Components
      </P>

      <P>Please get in touch if you fancy a chat</P>

      <ContactForm />
      <BtnWrapper>
        <Link href="https://www.linkedin.com/in/louise-reid/" target="_blank">
          <LinkdIn />
        </Link>
        <Link href={PDF} target="_blank">
          <CVIcon />
        </Link>
      </BtnWrapper>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  )
}

export default Home
