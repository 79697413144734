import { useForm } from "react-hook-form"
import { toast } from "react-toastify"

export const useContactForm = () => {
  const { register, handleSubmit } = useForm()
  const notify = () => toast.success("Thanks! I'll be in touch soon")

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&")
  }

  const onSubmit = (data) => {
    notify()
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...data }),
    })
  }

  return { onSubmit, handleSubmit, register }
}
