import React from "react"
import { Switch, Route } from "react-router-dom"
import styled from "styled-components"

import Home from "./components/pages/Home"

const Wrapper = styled.div`
  font-family: "Quicksand", sans-serif;
  height: 100vh;
  background: #abbaab; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #ffffff,
    #abbaab
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #ffffff,
    #abbaab
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`

const App = () => {
  return (
    <Wrapper>
      <Switch>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Wrapper>
  )
}

export default App
