import React from "react"
import MobileVideo from "../../../images/mobile.mp4"
import DesktopVideo from "../../../images/desktop.mp4"
import { useWindowSize } from "../../../hooks/useWindowResize"

const VideoPlayer = () => {
  const { width } = useWindowSize()
  const isDesktop = width >= 768

  const renderDesktop = window.matchMedia("(min-width: 768px)").matches

  return (
    <video
      id={isDesktop ? "desktopVideo" : "mobileVideo"}
      width={isDesktop ? "640" : "288"}
      height={isDesktop ? "360" : "512"}
      controls
    >
      {renderDesktop ? (
        <source src={DesktopVideo} type="video/mp4" />
      ) : (
        <source src={MobileVideo} type="video/mp4" />
      )}
      Your browser does not support this video format.
    </video>
  )
}

export default VideoPlayer
