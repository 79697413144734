import React from "react"
import styled from "styled-components"
import { useContactForm } from "./useForm"

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 0 20px 0;
  width: 100%;

  input[type="text"],
  input[type="email"],
  textarea {
    font-family: inherit;
    background-color: #abbaab2b;
    border: none;
    margin: 5px 0;
    padding: 10px 5px;
    font-size: 16px;
  }

  textarea {
    height: 100px;
  }

  input[type="submit"] {
    -webkit-appearance: none;
    font-size: 18px;
    font-weight: 300;
    background-color: #abbaab8f;
    padding: 12px 20px;
    border-radius: 4px;
    box-shadow: 2px 2px 3px #777c7d;
    outline: none;
    border: none;
    cursor: pointer;
    position: relative;
    align-self: flex-end;
    margin-top: 5px;

    &:hover {
      top: 2px;
      box-shadow: 2px 2px 5px #777c7d;
    }
    &:active {
      box-shadow: none;
      top: 6px;
    }
  }

  @media (min-width: 768px) {
    width: 40%;
  }
`

const ContactForm = () => {
  const { onSubmit, handleSubmit, register } = useContactForm()

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <input
        type="text"
        name="name"
        ref={register({ required: true })}
        placeholder="Your name"
      />
      <input
        type="email"
        name="email"
        ref={register({ required: true })}
        placeholder="Your email"
      />
      <textarea
        name="message"
        ref={register({ required: true })}
        placeholder="Your message..."
      />
      <input type="submit" value="Send" />
    </Form>
  )
}

export default ContactForm
