import React from "react"

const CVIcon = () => (
  <svg
    id="Capa_1"
    height="75"
    viewBox="0 0 512 512"
    width="75"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g>
        <path
          fill="#abbaab"
          d="m310.217 93.67c4.497 0 8.832 1.323 12.538 3.826 3.434 2.318 8.097 1.416 10.416-2.017 2.32-3.434 1.416-8.097-2.017-10.416-6.195-4.185-13.436-6.398-20.937-6.398-20.643 0-37.438 16.795-37.438 37.438s16.795 37.439 37.438 37.439c8.251 0 15.658-2.713 21.42-7.844 1.131-1.007 2.203-2.114 3.189-3.289 2.662-3.176 2.245-7.908-.93-10.57-3.175-2.663-7.908-2.245-10.569.93-.519.619-1.081 1.2-1.67 1.724-3.013 2.683-6.862 4.044-11.44 4.044-12.37 0-22.434-10.064-22.434-22.434 0-12.369 10.064-22.433 22.434-22.433z"
        />
        <path
          fill="#abbaab"
          d="m384.903 153.541h.06c3.765-.025 7.11-2.437 8.339-6.053l19.549-58.846c1.306-3.932-.823-8.178-4.755-9.485-3.933-1.308-8.179.822-9.485 4.754l-13.836 41.649-14.528-41.853c-1.359-3.915-5.634-5.988-9.548-4.627-3.914 1.359-5.986 5.633-4.627 9.547l20.488 59.023c1.254 3.531 4.602 5.891 8.343 5.891z"
        />
        <path
          fill="#abbaab"
          d="m410.919 243.935h-140.705c-4.143 0-7.502 3.359-7.502 7.502s3.359 7.502 7.502 7.502h140.705c4.143 0 7.502-3.359 7.502-7.502s-3.358-7.502-7.502-7.502z"
        />
        <path
          fill="#abbaab"
          d="m270.214 304.959h70.353c4.143 0 7.502-3.358 7.502-7.502s-3.359-7.502-7.502-7.502h-70.353c-4.143 0-7.502 3.358-7.502 7.502s3.359 7.502 7.502 7.502z"
        />
        <path
          fill="#abbaab"
          d="m410.919 335.974h-140.705c-4.143 0-7.502 3.358-7.502 7.502s3.359 7.502 7.502 7.502h140.705c4.143 0 7.502-3.359 7.502-7.502s-3.358-7.502-7.502-7.502z"
        />
        <path
          fill="#abbaab"
          d="m418.422 389.496c0-4.144-3.359-7.502-7.502-7.502h-140.706c-4.143 0-7.502 3.358-7.502 7.502s3.359 7.502 7.502 7.502h140.705c4.144.001 7.503-3.358 7.503-7.502z"
        />
        <path
          fill="#abbaab"
          d="m270.214 429.242c-4.143 0-7.502 3.358-7.502 7.502s3.359 7.502 7.502 7.502h70.353c4.143 0 7.502-3.359 7.502-7.502s-3.359-7.502-7.502-7.502z"
        />
        <path
          fill="#abbaab"
          d="m410.919 197.915h-140.705c-4.143 0-7.502 3.359-7.502 7.502s3.359 7.502 7.502 7.502h140.705c4.143 0 7.502-3.359 7.502-7.502s-3.358-7.502-7.502-7.502z"
        />
        <path
          fill="#abbaab"
          d="m450.916 105.271c4.143 0 7.502-3.359 7.502-7.502v-69.058c.001-15.831-12.879-28.711-28.711-28.711h-347.414c-15.832 0-28.712 12.88-28.712 28.711v454.577c0 15.831 12.88 28.711 28.712 28.711h347.413c15.832 0 28.712-12.88 28.712-28.711v-350.445c0-4.144-3.359-7.502-7.502-7.502s-7.502 3.359-7.502 7.502v350.446c0 7.558-6.149 13.707-13.707 13.707h-191.213v-481.991h191.212c7.558 0 13.707 6.148 13.707 13.707v69.058c.001 4.143 3.36 7.501 7.503 7.501zm-227.427 391.724h-141.196c-7.558 0-13.707-6.148-13.707-13.707v-454.577c0-7.558 6.149-13.707 13.707-13.707h141.196z"
        />
        <path
          fill="#abbaab"
          d="m146.133 175.586c32.697 0 59.483-26.623 59.483-59.483 0-32.799-26.684-59.483-59.483-59.483s-59.483 26.684-59.483 59.483c0 32.82 26.746 59.483 59.483 59.483zm0-15.005c-9.72 0-18.714-3.143-26.04-8.453 3.123-6.322 8.521-11.396 15.059-14.091 3.336 1.569 7.057 2.451 10.981 2.451s7.645-.882 10.981-2.451c6.538 2.695 11.936 7.769 15.059 14.091-7.325 5.311-16.32 8.453-26.04 8.453zm-10.89-45.988c0-6.006 4.885-10.891 10.891-10.891 6.005 0 10.891 4.885 10.891 10.891 0 6.005-4.885 10.89-10.891 10.89s-10.891-4.885-10.891-10.89zm10.89-42.968c24.526 0 44.478 19.953 44.478 44.478 0 9.171-2.791 17.702-7.567 24.79-3.639-5.618-8.499-10.333-14.256-13.774 2.062-3.715 3.241-7.985 3.241-12.526 0-14.279-11.617-25.896-25.896-25.896s-25.896 11.617-25.896 25.896c0 4.542 1.179 8.811 3.241 12.526-5.758 3.441-10.618 8.156-14.256 13.774-4.776-7.088-7.567-15.619-7.567-24.79 0-24.525 19.953-44.478 44.478-44.478z"
        />
        <path
          fill="#abbaab"
          d="m102.663 285.222h86.94c8.935 0 16.204-7.269 16.204-16.203v-21.435c0-8.935-7.269-16.203-16.204-16.203h-86.94c-8.935 0-16.204 7.269-16.204 16.203v21.435c0 8.934 7.269 16.203 16.204 16.203zm-1.199-37.638c0-.661.538-1.199 1.199-1.199h86.94c.661 0 1.199.537 1.199 1.199v21.435c0 .661-.538 1.199-1.199 1.199h-86.94c-.661 0-1.199-.537-1.199-1.199z"
        />
        <path
          fill="#abbaab"
          d="m102.663 364.734h7.57c4.143 0 7.502-3.358 7.502-7.502s-3.359-7.502-7.502-7.502h-7.57c-.661 0-1.199-.537-1.199-1.199v-21.435c0-.661.538-1.199 1.199-1.199h86.94c.661 0 1.199.537 1.199 1.199v21.435c0 .661-.538 1.199-1.199 1.199h-44.297c-4.143 0-7.502 3.359-7.502 7.502s3.359 7.502 7.502 7.502h44.297c8.935 0 16.204-7.269 16.204-16.203v-21.435c0-8.935-7.269-16.203-16.204-16.203h-86.94c-8.935 0-16.204 7.269-16.204 16.203v21.435c0 8.935 7.269 16.203 16.204 16.203z"
        />
        <path
          fill="#abbaab"
          d="m102.663 444.247h86.94c8.935 0 16.204-7.269 16.204-16.203v-21.435c0-8.935-7.269-16.203-16.204-16.203h-86.94c-8.935 0-16.204 7.269-16.204 16.203v21.435c0 8.934 7.269 16.203 16.204 16.203zm-1.199-37.639c0-.661.538-1.199 1.199-1.199h86.94c.661 0 1.199.537 1.199 1.199v21.435c0 .661-.538 1.199-1.199 1.199h-86.94c-.661 0-1.199-.537-1.199-1.199z"
        />
      </g>
    </g>
  </svg>
)

export default CVIcon
